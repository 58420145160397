import { create } from "@storybook/theming";

export const youleapTheme = create({
  base: "light",

  //Logo
  brandTitle: "Youleap Storybook",
  brandUrl: "https://youleap.com",
  brandImage: "/youleap-logo-dark.svg",
  brandTarget: "_self",

  //Colors
  colorPrimary: "#F15752",
  colorSecondary: "#17B2E5",

  // Typography
  fontBase: '"Source Sans Pro", "Open Sans", sans-serif',
  fontCode: "monospace",
});
